import '../styles/suppliers.css';
import SupplierModal from './SupplierModal';
import { useState } from 'react';

function Suppliers(props){
    const [isOpen, setIsOpen] = useState(false);
    const [modal_data, setModalData] = useState({id:null, name:null, logo_path:null});

    const suppliers = props.suppliers;

    const search = (e) => {
        const value = e.target.value.toLowerCase();
        const cards = document.querySelectorAll(".supplier-card");
        cards.forEach((card) => {
            const name = card.querySelector("p").textContent.toLowerCase();
            if(name.includes(value)){
                card.style.display = "flex";
            }else{
                card.style.display = "none";
            }
        });
    }

    return(
        <div className="suppliers">
            <h2>Proveedores</h2>
            <input type="text" placeholder="Buscar..." onChange={search}/>
            <div className="main-pannel">
                <button className="add-supplier-button" id="add_supplier" onClick={() => {setIsOpen(true); setModalData({id:null, name:null, logo_path:null});}}>
                    <span>+</span>
                    <p>NUEVO PROVEEDOR</p>
                </button>

                {suppliers && suppliers.map((supplier) => (
                    <div className="supplier-card" key={supplier.id}  onClick={() => { setIsOpen(true); setModalData(supplier); }}>
                        <img src={"https://api.diproalvi.com/uploads/"+supplier.logo_path} alt="Supplier"/>
                        <p>{supplier.name}</p>
                    </div>
                ))}
            </div>
            <div className="pagination-container">

            </div>
            {isOpen && <SupplierModal setIsOpen={setIsOpen}  modal_data={modal_data}/>}
        </div>
    );
}

export default Suppliers;