import '../styles/categories.css';
import CategoryModal from './CategoryModal';
import { useState } from 'react';

function Categories(props){
    const [isOpen, setIsOpen] = useState(false);
    const [modal_data, setModalData] = useState({id:null, name:null, parent_id:null, image_path:null});
    const categories = props.categories;

    const search = (e) => {
        const value = e.target.value.toLowerCase();
        const cards = document.querySelectorAll(".category-card");
        cards.forEach((card) => {
            const name = card.querySelector("p").textContent.toLowerCase();
            if(name.includes(value)){
                card.style.display = "block";
            }else{
                card.style.display = "none";
            }
        });
    }

    return(
        <div className="categories">
            <h2>Categorías</h2>
            <input type="text" placeholder="Buscar..." onChange={search}/>
            <div className="main-pannel">
                <button className="add-category-button" id="add_category" onClick={() => {setIsOpen(true);  setModalData({id:null, name:null, parent_id:null, image_path:null});}}>
                    <span>+</span>
                    <p>NUEVA CATEGORÍA</p>
                </button>

                {categories && categories.map((category) => (
                    <div className="category-card" key={category.id} onClick={() => { setIsOpen(true); setModalData(category); }}>
                        <img src={"https://api.diproalvi.com/uploads/"+category.image_path} alt="Category"/>
                        <p>{category.name}</p>
                    </div>
                ))}

            </div>
            <div className="pagination-container">

            </div>
            {isOpen && <CategoryModal setIsOpen={setIsOpen} modal_data={modal_data} categories = {categories}/>}
        </div>
    );
}

export default Categories;