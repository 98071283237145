import '../styles/categoryModal.css'


const CategoryModal = (props) => {
    const setIsOpen = props.setIsOpen;
    const modal_data = props.modal_data;
    const categories = props.categories;


    return ( 
        <div className="category-modal modal">
            <div className="modal-content">
                <h3>Modificar Categoría</h3>
                <button className="close-modal-button" onClick={() => setIsOpen(false)}>×</button>
                <form id="category_form" className="category-form" action="https://api.diproalvi.com/index.php/categories/" method="post" encType="multipart/form-data">
                    <input type="hidden" name="id" defaultValue={modal_data.id??""}/>
                    <div className="input-wrapper">
                        <label htmlFor="category_name">Nombre</label>
                        <input type="text" id="category_name" name="name" defaultValue={modal_data.name??""} required/>
                    </div> 
                    <div className="input-wrapper">
                        <label htmlFor="category_partent_category">Categoría Padre</label>
                        <select name="parent_id" id="category_partent_category" defaultValue={modal_data.parent_id??0} required>
                            <option value="2">Ninguna</option>
                            {categories && categories.map((category) => (
                                category.id !== modal_data.id &&
                                <option key={category.id} value={category.id}>{category.name}</option>
                            ))}
                        </select>
                    </div> 
                    <div className="input-wrapper">
                        <label htmlFor="category_priority">Prioridad</label>
                        <input type="number" min="1" step="1" id="category_priority" name="priority" defaultValue={modal_data.priority??"1"} required/>
                    </div> 
                    <div className="input-wrapper photo-input-wrapper">
                        <label>Foto</label>
                        <input type="file" id="category_photo" name="image" hidden accept="image/png, image/gif, image/jpeg" onChange={(e) => { 
                            const [file] = e.target.files;
                            if (file) {
                                document.querySelector(".photo-preview>img").src = URL.createObjectURL(file);
                            }
                        }}/>
                        <label className="photo-preview" htmlFor="category_photo" >
                            <img src={modal_data.image_path?"https://api.diproalvi.com/uploads/"+modal_data.image_path:"/misc/default-placeholder.png"} alt="Foto de la categoría"/>
                        </label>
                    </div>

                    <button type="submit">Aplicar</button>
                </form>  
            </div>
        </div>
     );
}
 
export default CategoryModal;