const VariantModalRow = (props) => {
    const row_id = props.row_id;

    return (  
        <div className="variant-input-row" id={row_id} key={row_id}>
            <input type="hidden" name={"variant_id_"+row_id} defaultValue={props.variant_id??""}/>
            <div className="input-wrapper">
                <label htmlFor={"variant_sku_"+row_id}>SKU</label>
                <input type="text" id={"variant_sku_"+row_id} name={"sku_"+row_id} defaultValue={props.variant.sku} required/>
            </div>
            <div className="input-wrapper">
                <label htmlFor={"variant_color_"+row_id}>Color</label>
                <input type="color" id={"variant_color_"+row_id} name={"hex_"+row_id} defaultValue={props.variant.hex} required />
            </div>
            <div className="photos-wrapper">
                <div className="photo-wrapper">
                    <label>Foto principal</label>
                    <input type="file" id={"variant_main_photo_"+row_id} name={"main_image_"+row_id} hidden accept="image/png, image/gif, image/jpeg" onChange={
                        (e) => {
                            const [file] = e.target.files;
                            if (file) {
                                document.querySelector("#variant_main_photo_"+row_id+"_label>img").src = URL.createObjectURL(file);
                            }
                        }
                    }/>
                    <label id={"variant_main_photo_"+row_id + "_label"} htmlFor={"variant_main_photo_"+row_id} className="upload-photo-button variant-main-upload-photo"><img src={props.variant.main_image_path?"https://api.diproalvi.com/uploads/"+props.variant.main_image_path:"/misc/default-placeholder.png"} alt="Imagen principal variante"/></label>
                </div>
            </div>
        </div>
    );
}
 
export default VariantModalRow;