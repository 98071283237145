import { Link } from 'react-router-dom';
import '../styles/sidePannel.css'
import { useState } from 'react';



function SidePannel(){
    const [activeName, setActiveName] = useState(window.location.href.split('/')[window.location.href.split('/').length - 1]);
    return(
        <div className="side-pannel">
            <img src="/logos/Logo monocromatico claro.png" alt="logo Diproalvi claro" />
            <div>
                <Link name="categories" to="/categories" className={activeName === 'categories'? 'active':''} onClick={(e)=>{setActiveName(e.target.name)}}>Categorías</Link>
                <Link name="products" to="/products" className={activeName === 'products'? 'active':''} onClick={(e)=>{setActiveName(e.target.name)}}>Productos</Link>
                <Link name="suppliers" to="/suppliers" className={activeName === 'suppliers'? 'active':''} onClick={(e)=>{setActiveName(e.target.name)}}>Proveedores</Link>
            </div>
        </div>
    );
}

export default SidePannel;