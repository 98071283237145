import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import SidePannel from './components/SidePannel';
import Categories from './components/Categories';
import Products from './components/Products';
import Suppliers from './components/Suppliers';
import { useState, useEffect } from 'react';



function App() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
      fetch('https://api.diproalvi.com/index.php/products/', {
          method: 'GET',
          headers: { 
              'Content-Type': 'application/json'
          }    
      }).then(response => {
          return response.json();
      }).then(data => {
          setProducts(data);
      });
      fetch('https://api.diproalvi.com/index.php/categories/', {
          method: 'GET',
          headers: { 
              'Content-Type': 'application/json'
          }    
      }).then(response => {
          return response.json();
      }).then(data => {
          setCategories(data);
      });
      fetch('https://api.diproalvi.com/index.php/suppliers/', {
          method: 'GET',
          headers: { 
              'Content-Type': 'application/json'
          }    
      }).then(response => {
          return response.json();
      }).then(data => {
          setSuppliers(data);
      });
  }, []);

  return (
    <Router>
    <div className="App">
      <SidePannel />
      <div className="content">
        <Switch>
          <Route exact path="/">
            <Redirect to="/categories" />
          </Route>
          <Route path="/categories">
            <Categories categories = {categories}/>
          </Route>
          <Route path="/products">
            <Products categories={categories} suppliers={suppliers} products={products}/>
          </Route>
          <Route path="/suppliers">
            <Suppliers  suppliers = {suppliers}/>
          </Route>
        </Switch>
      </div>
    </div>
    </Router>
  );
}

export default App;
