import '../styles/productModal.css'
import { useState, useEffect } from 'react';
import VariantModal from './VariantModal';

const ProductModal = (props) => {
    const setIsOpen = props.setIsOpen;
    const [isSecondOpen, setIsSecondOpen] = useState(false);
    const modal_data = props.modal_data;
    const categories = props.categories;
    const suppliers = props.suppliers;

    const [product_secondary_images, setProductSecondaryImages] = useState();
    useEffect(() => {
        fetch('https://api.diproalvi.com/index.php/product-secondary-images?product_id='+modal_data.id, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            }    
        }).then(response => {
            return response.json();
        }).then(data => {
            setProductSecondaryImages(data);
        });
    }, [modal_data]);

    const previewSecondary = (file) => {
        const fr = new FileReader();
        fr.onload = () => {
          const img = document.createElement("img");
          img.src = fr.result;  // String Base64 
          img.alt = file.name;
          document.querySelector('.secondary-upload-photos').prepend(img);
        };
        fr.readAsDataURL(file);
    };
      

    return ( 
        <div className="product-modal modal">
            <div className="modal-content">
                <h3>{modal_data.id ? "Modificar Producto": "Nuevo Producto"}</h3>
                {modal_data.id ? <button className="delete-button" onClick={
                    (e) => {
                        e.preventDefault();
                        fetch('https://api.diproalvi.com/index.php/products/'+modal_data.id, {
                            method: 'DELETE'
                        }).then(response => {
                            if (response.status === 200) {
                                setIsOpen(false);
                                window.location.reload();
                            }
                        });
                    }
                }>Eliminar</button> : ""
                }
                <button className="close-modal-button"  onClick={() => setIsOpen(false)}>×</button>
                <form id="product_form" className="product-form" action="https://api.diproalvi.com/index.php/products/" method="post" encType="multipart/form-data">
                    <input type="hidden" name="id" defaultValue={modal_data.id??""}/>
                    <div className="sides-container">
                        <div className="left-side side">
                            <div className="input-wrapper">
                                <label htmlFor="product_name">Nombre</label>
                                <input type="text" id="product_name" name="name" defaultValue={modal_data.name??""} required/>
                            </div> 
                            <div className="input-wrapper">
                                <label htmlFor="product_sku">SKU</label>
                                <input type="text" id="product_sku" name="sku" defaultValue={modal_data.sku??""} required/>
                            </div> 
                            <div className="input-wrapper">
                                <label htmlFor="product_category">Categoría</label>
                                <select id="product_category" name="category_id" defaultValue={modal_data.category_id??2} required>
                                    <option value="2">Ninguna</option>
                                    {categories && categories.map((category) => (
                                        <option key={category.id} value={category.id}>{category.name}</option>
                                    ))}
                                </select>
                            </div> 
                            <div className="input-wrapper">
                                <label htmlFor="product_supplier">Proveedor</label>
                                <select name="supplier_id" id="product_supplier" defaultValue={modal_data.supplier_id??3} required>
                                    <option value="1">Ninguno</option>
                                    {suppliers && suppliers.map((supplier) => (
                                        <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                    ))}
                                </select>
                            </div> 
                            {
                            modal_data.id ?
                            <div className="input-wrapper">
                                <button className="variant-button" onClick={
                                    (e) => {
                                        e.preventDefault();
                                        setIsSecondOpen(true); 
                                    }
                                }>
                                    Ver Variantes
                                </button>
                            </div>
                            : ""
                            }
                        </div>

                        <div className="right-side side">
                            <div className="input-wrapper">
                                <label htmlFor="product_description">Descripción</label>
                                <textarea id="product_description" name="description" cols="30" rows="10"  defaultValue={modal_data.description??""} required></textarea>
                            </div>

                            <div className="photos-wrapper">
                                <div className="photo-wrapper">
                                    <label>Foto principal</label>
                                    <input type="file" id="product_main_photo" name="main_image" hidden accept="image/png, image/gif, image/jpeg" onChange={(e) => { 
                                        const [file] = e.target.files;
                                        if (file) {
                                            document.querySelector(".main-upload-photo>img").src = URL.createObjectURL(file);
                                        }
                                    }}/>
                                    <label htmlFor="product_main_photo" className="upload-photo-button main-upload-photo"><img src={modal_data.main_image_path?"https://api.diproalvi.com/uploads/"+modal_data.main_image_path:"/misc/default-placeholder.png"} alt="Imagen principal producto"/></label>
                                </div>
                                <div className="photo-wrapper">
                                    <label>Fotos secundarias</label>

                                    <div className="secondary-photos-conatainer">                                     
                                    
                                        {product_secondary_images && product_secondary_images.map((image) => (
                                            <div key={image.id}>
                                                <input type="file" id={"product_secondary_photo_"+image.id} name={"product_secondary_photo_"+image.id} hidden accept="image/png, image/gif, image/jpeg " onChange={(e) => {
                                                    const files = e.target.files;
                                                    if (files) {
                                                        const images = document.querySelectorAll("#product_secondary_photo_label_"+image.id+">img");
                                                        for (let i = 0; i < files.length; i++) {
                                                            images[i].src = URL.createObjectURL(files[i]);
                                                        }
                                                    }
                                                }}/>
                                                <label id={"product_secondary_photo_label_"+image.id} htmlFor={"product_secondary_photo_"+image.id} className="upload-photo-button secondary-upload-photo"><img src={"https://api.diproalvi.com/uploads/"+image.image_path} alt="Imagen secundaria producto"/></label>
                                            </div>
                                        ))
                                        }
                                        <div>
                                            <input type="file" id="product_secondary_photos" name="secondary_images[]" hidden accept="image/png, image/gif, image/jpeg " multiple onChange={(e) => {
                                                const files = e.target.files;
                                                if (files) {
                                                    for (let i = 0; i < files.length; i++) {
                                                        previewSecondary(files[i]);
                                                    }
                                                }
                                            }}/>
                                            <label htmlFor="product_secondary_photos" className="upload-photo-button secondary-upload-photos">
                                                <img src="/misc/default-placeholder.png" alt="Imagen secundaria producto"/>
                                            </label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <button type="submit">Aplicar</button>
                </form>  
            </div>
            {isSecondOpen && <VariantModal setIsSecondOpen={setIsSecondOpen} product_id={modal_data.id} colors = {props.colors}/>}
        </div>
     );
}
 
export default ProductModal;



