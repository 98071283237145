import '../styles/supplierModal.css'


const SupplierModal = (props) => {
    const setIsOpen = props.setIsOpen;
    const modal_data = props.modal_data;


    return ( 
        <div className="supplier-modal modal">
            <div className="modal-content">
                <h3>Modificar Proveedor</h3>
                <button className="close-modal-button" onClick={() => setIsOpen(false)}>×</button>
                <form id="supplier_form" className="supplier-form" action="https://api.diproalvi.com/index.php/suppliers/" method="post" encType="multipart/form-data">
                    <input type="hidden" name="id" defaultValue={modal_data.id??""}/>
                    <div className="input-wrapper">
                        <label htmlFor="supplier_name">Nombre</label>
                        <input type="text" id="supplier_name" name="name"  defaultValue={modal_data.name??""} required/>
                    </div> 
                    <div className="input-wrapper photo-input-wrapper">
                        <label>Logo</label>
                        <input type="file" id="logo" name="logo" hidden accept="image/png, image/gif, image/jpeg" onChange={(e) => { 
                            const [file] = e.target.files;
                            if (file) {
                                document.querySelector(".photo-preview>img").src = URL.createObjectURL(file);
                            }
                        }}/>
                        <label className="photo-preview" htmlFor="logo">
                            <img src={modal_data.logo_path?"https://api.diproalvi.com/uploads/"+modal_data.logo_path:"/misc/default-placeholder.png"} alt="Foto de la categoría"/>
                        </label>
                    </div>

                    <button type="submit">Aplicar</button>
                </form>  
            </div>
        </div>
     );
}
 
export default SupplierModal;