import '../styles/products.css';
import ProductModal from './ProductModal';
import { useState } from 'react';

function Products(props){
    const [isOpen, setIsOpen] = useState(false);
    const default_modal_data = {"id":0,"category_id":null,"sku": null,"name": null,"description": null,"supplier_id":0,"main_image_path":null};
    const [modal_data, setModalData] = useState(default_modal_data);

    const categories = props.categories;
    const suppliers = props.suppliers;
    const products = props.products;

    const search = (e) => {
        const value = e.target.value.toLowerCase();
        const cards = document.querySelectorAll(".product-card");
        cards.forEach((card) => {
            const name = card.querySelector("p").textContent.toLowerCase();
            const code = card.querySelector("span").textContent.toLowerCase();
            if(name.includes(value) || code.includes(value)){
                card.style.display = "flex";
            }else{
                card.style.display = "none";
            }
        });
    }

    return(
        <div className="products">
            <h2>Productos</h2>
            <input type="text" placeholder="Buscar..." onChange={search} />
            <div className="main-pannel">
                <button className="add-product-button" id="add_product"  onClick={() => {setIsOpen(true); setModalData(default_modal_data)}}>
                    <span>+</span>
                    <p>NUEVO PRODUCTO</p>
                </button>

                {products && products.map((product) => (
                    <div className="product-card" key={product.id} onClick={() => { setIsOpen(true); setModalData(product); }}>
                        <img src={"https://api.diproalvi.com/uploads/"+product.main_image_path} alt="Producto"/>
                        <p>{product.name}</p>
                        <span>{product.sku}</span>
                    </div>
                ))}

            </div>
            <div className="pagination-container">

            </div>
            {isOpen && <ProductModal setIsOpen={setIsOpen} modal_data={modal_data} categories = {categories} suppliers={suppliers} colors={props.colors}/>}
        </div>
    );
}

export default Products;