import '../styles/productModal.css'
import VariantModalRow from './VariantModalRow';
import { useEffect, useState } from 'react';

const VariantModal = (props) => {
    const setIsSecondOpen = props.setIsSecondOpen;
    const product_id = props.product_id;
    const [existingVariants, setExistingVariants] = useState([]);
    useEffect(() => {
        fetch('https://api.diproalvi.com/index.php/color-variants?product_id='+product_id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            setExistingVariants(data);
        });
    }, [product_id]);
    


    const removeRow = (e) => {
        const newVariantRows = [...variantRows];
        if (newVariantRows.length === 1 && existingVariants.length === 0) {
            return;
        }
        newVariantRows.pop();
        setVariantRows(newVariantRows);
    }

    const [variantRows, setVariantRows] = useState([<VariantModalRow row_id="0" key="0" variant={{"id":null,"sku":null,"hex":null,"main_image_path":null}}/> ]);

    const addVariantRow = () => {
        const newVariantRows = [...variantRows];
        const lastRowId = newVariantRows[newVariantRows.length - 1].props.row_id;
        newVariantRows.push(<VariantModalRow row_id={parseInt(lastRowId) + 1} key={parseInt(lastRowId) +1} variant={{"id":null,"sku":null,"hex":null,"main_image_path":null}}/>);
        setVariantRows(newVariantRows);
    }

    return ( 
        <div className="product-modal modal variant-modal">
            <div className="modal-content">
                <h3>Nueva Variante de Color</h3>
                <button className="close-modal-button"  onClick={() => setIsSecondOpen(false)}>←</button>
                <form id="variant_form" className="product-form" action="https://api.diproalvi.com/index.php/color-variants/" method="post" encType="multipart/form-data">
                    <input type="hidden" name="product_id" defaultValue={product_id}/>
                    
                    <div className="variant-input-container">
                        {
                            existingVariants.map((variant, index) => {
                                return <VariantModalRow row_id={"e"+index} key={"e"+index} variant={variant} variant_id={variant.id}/>
                            })                                
                        }
                        {variantRows}
                    </div>
                    <div className="input-wrapper button-wrapper">
                        <button className="add-color-variant-button"
                            onClick={(e) => { 
                                e.preventDefault();
                                addVariantRow();
                            }}
                        >+ Añadir variante de color</button>
                    
                        <button className="remove-variant-input-row" onClick={
                            (e) => {
                                e.preventDefault();
                                removeRow();
                            }
                        }>×</button>
                    </div> 
                    <button type="submit">Aplicar</button>
                </form>  
            </div>
        </div>
     );
}
 
export default VariantModal;



